













































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
@Component({
  components: {}
})
export default class P0209 extends Vue {
  //選択肢定数
  readonly STEP_NEW = 'new'; //OPポイント専用カードの新規登録
  readonly STEP_CONTINUATION = 'continuation'; //各種ポイント情報照会などの機能の継続利用
  readonly STEP_NOT_REGISTERED = 'not_registered'; //小田急ONE IDをお持ちでない方
  readonly STEP_REGISTERED = 'registered'; //すでに小田急ONE IDをお持ちの方

  //注意事項表示フラグ
  isNoticeOpen: Boolean[] = [false, false];

  //STEP1,2状態
  stepStatus01 = '';
  stepStatus02 = '';

  //注意事項開閉
  toggleNotice(index: any) {
    if (this.isNoticeOpen[index]) {
      this.$set(this.isNoticeOpen, index, false);
    } else {
      this.$set(this.isNoticeOpen, index, true);
    }
  }

  //ログイン
  clickLogin() {
    this.$auth.loginWithRedirect({
      appState: { targetUrl: '/odakyu-point?tab=op-card' }
    });
  }

  //新規登録
  signup() {
    this.$auth.loginWithRedirect({ initialDisplay: 'signup' });
  }
}
